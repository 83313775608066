/** @format */

import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { Link, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Image, Nav, Navbar } from "react-bootstrap";
import login_logo from "../assets/logo/LIV_WhiteLogo.svg";
import instagram from "../assets/buttons/instagram.png";
import linkedin from "../assets/buttons/linkedin.png";
import facebook from "../assets/buttons/facebook.png";
import black_logo from "../assets/logo/LIV_BlackLogo.svg";
import account_icon from "../assets/buttons/profileicon.png";
import "../css/style.css";
import "../css/submitted.css";
import Login from "./Login";
import Clients from "./Clients";
import SignUp from "./SignUp";
import Account from "./Account";
import { addToken, onASNameChange } from "../actions";
import { GLOBAL_DOMAIN } from "../actions/types";
import axios from "axios";
import proximanova_bold from "../assets/fonts/Proxima_Nova_Bold.otf";

function Root(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [account, setAccount] = useState(false);
  const { accountId, contactId } = useSelector((state) => state.auth);

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyA3nFZK18EdfnPQ08pAwrUq0SpvtbAozXc",
      authDomain: "property-portal-60eeb.firebaseapp.com",
      projectId: "property-portal-60eeb",
      storageBucket: "property-portal-60eeb.appspot.com",
      messagingSenderId: "900435700811",
      appId: "1:900435700811:web:71758952a0f373a449b1ce",
    };
    initializeApp(firebaseConfig);
  }, []);

  useEffect(() => {
    const LScontactId = localStorage.getItem("contactId");
    const LSaccountId = localStorage.getItem("accountId");
    const LSToken = localStorage.getItem("token");

    if (LScontactId === "undefined" || LSaccountId === "undefined") {
      setIsLoggedIn(false);
      // <Redirect to="/login" />;
    } else if (LScontactId && LSaccountId) {
      setIsLoggedIn(true);
      // <Redirect to="/clients" />;
    }

    if (!LSToken) {
      getSalesforceToken();
    } else {
      props.addToken(LSToken);
      props.onASNameChange(null, LSaccountId, LScontactId);
    }
    // eslint-disable-next-line
  }, [props, accountId, contactId]);

  const getSalesforceToken = async () => {
    try {
      let response = await axios.get(`${GLOBAL_DOMAIN}/api/auth/token`);
      props.addToken(response?.data);

      return { success: true, access_token: response.data };
    } catch (err) {
      console.log("getSalesforceToken err on login");
      return { success: false, error: err };
    }
  };

  const expand = window.innerWidth > 1300;
  const spacer = expand ? "50px" : "25px";

  // if (isLoggedIn) {
  //   setTimeout(() => {
  //     setIsLoggedIn(false);
  //   }, 2000);
  //   return <Redirect to="/clients" />;
  // }
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* <div style={{ flex: 1 }}> */}
      {!isLoggedIn ? (
        <>
          <div
            style={{
              height: "40vh",
              width: "100%",
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              padding: "15px 0px",
            }}
          >
            <Image src={login_logo} fluid />
          </div>
          <Switch style={{}}>
            <Route
              exact
              path="/login"
              component={() => (
                <Login
                  isLoggedIn={() => {
                    setIsLoggedIn(true);
                  }}
                />
              )}
            />
            <Route exact path="/sign-up">
              {!props.signedUp ? (
                (localStorage.removeItem("signedUp"), (<SignUp />))
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p className="center">
                    PLEASE CHECK YOUR INBOX AND VERIFY YOUR EMAIL.
                  </p>
                  <Link to="/login" className="button">
                    LOGIN
                  </Link>
                </div>
              )}
            </Route>
            <Route
              path="/"
              component={() => (
                <Login
                  isLoggedIn={() => {
                    setIsLoggedIn(true);
                    <Redirect to="/clients" />;
                  }}
                />
              )}
            />
          </Switch>
        </>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Navbar
            expand="lg"
            className="bs-navbar-collapse"
            style={{
              // top: "54px",
              justifyContent: "space-between",
              margin: "50px",
              padding: "0px 0px",
              alignItems: "center",
              // width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Navbar.Brand
              as={Link}
              to="/clients"
              onClick={() => {
                setAccount(false);
                // <Redirect to="/clients"/>
              }}
            >
              <Image
                style={{
                  height: "81px",
                }}
                src={black_logo}
              ></Image>
            </Navbar.Brand>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto" activeKey="/clients">
                <span style={{ width: spacer }} />
                <button
                  style={{
                    border: "none",
                    backgroundColor: "white",
                  }}
                  className="account"
                  onClick={() => {
                    setAccount(!account);
                  }}
                >
                  <img
                    src={account_icon}
                    style={{ transform: "scale(0.9)" }}
                    alt="account"
                  ></img>
                </button>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "-50px",
            }}
          >
            <p
              style={{
                fontSize: "28px",
                color: "black",
                fontFamily: proximanova_bold,
              }}
            >
              Property Portal
            </p>
          </div>
          <div
            className="account_toggle"
            style={{
              display: !account && "none",
            }}
            onClick={() => {
              setAccount(true);
            }}
          >
            { <Account />}
            {/* onASClearFields={this.props.onASClearFields} /> */}
          </div>
          <Switch>
            <Route exact path="/" component={Clients} />
            <Route exact path="/clients" component={Clients} />

            {/* <Route exact path="/sign-up">
                {!props.signedUp ? (
                  (localStorage.removeItem("signedUp"), (<SignUp />))
                ) : (
                  <div
                    style={{
                      marginTop: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p className="center">
                      PLEASE CHECK YOUR INBOX AND VERIFY YOUR EMAIL.
                    </p>
                    <Link to="/login" className="button">
                      LOGIN
                    </Link>
                  </div>
                )}
              </Route> */}
            <Route
              path="/"
              component={() => (
                <Redirect to="/clients" />
                // <div style={{ display: "flex", justifyContent: "center" }}>
                //   Not Found
                // </div>
              )}
            />
          </Switch>
        </div>
      )}
      {/* </div> */}

      <div className="footer">
        <div className="nav_spacer"></div>
        <div style={{ display: "flex" }}>
          <a
            href="https://www.instagram.com/livunltd/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              style={{
                border: "1px white solid",
                borderRadius: "100px",
                backgroundColor: "white",
                transform: "scale(0.5)",
              }}
              src={instagram}
              alt="IG"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/liv-unltd/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{
                border: "1px white solid",
                borderRadius: "100px",
                backgroundColor: "white",
                transform: "scale(0.5)",
              }}
              src={linkedin}
              alt="LI"
            ></img>
          </a>
          <a
            href="https://www.facebook.com/LIVunLtd/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{
                border: "1px white solid",
                borderRadius: "100px",
                backgroundColor: "white",
                transform: "scale(0.5)",
              }}
              src={facebook}
              alt="FB"
            ></img>
          </a>
        </div>
        <div>
          <p style={{ margin: 0 }}>212-784-2390</p>
          <p style={{ margin: 0 }}>info@livunltd.com</p>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(null, { addToken, onASNameChange })(Root));
